import _ from "lodash";
import { useMemo } from "react";
import { PALETTES, PALETTE } from "~/colors";
import { Layer } from "./Layer";

const MODEL_LAYER_SIZES = [6, 7, 8];
const COLORS_PER_PALETTE = 6;

interface Pairing {
  modelIndex: number;
  colorIndex: number;
}

interface Props {
  colorPalette?: PALETTE | null
}

export function AttractionLoop({ colorPalette }: Props) {
  const palette = colorPalette || _.sample(PALETTES);
  const pairings = useBuildPairings();
  if (!palette) return null;

  return (
    <>
      {pairings.map((pairing, index) => (
        <Layer
          color_index={pairing.colorIndex}
          depth={index}
          model_index={pairing.modelIndex}
          key={index}
          palette={palette}
        />
      ))}
    </>
  );
}

function useBuildPairings(): Pairing[] {
  const numberOfModels = useMemo(() =>
    MODEL_LAYER_SIZES[Math.floor(Math.random() * MODEL_LAYER_SIZES.length)],
    []
  );

  return useMemo(() => {
    return _.range(0, numberOfModels).reduce((acc) => {
      const modelIndex = generateNextModel(acc);
      const colorIndex = generateNextColor(acc);

      acc.push({
        modelIndex,
        colorIndex,
      });
      return acc;
    }, [] as Pairing[]);
  }, [numberOfModels]);
}

function generateNextModel(previousPairs: Pairing[]) {
  const previousModels = previousPairs.map((pair: Pairing) => pair.modelIndex);
  const forbiddenModels: number[] = [];
  
  if (previousModels.length < 2) {
    forbiddenModels.push(2, 4, 12);
  } else {
    forbiddenModels.push(1, 17);
  }

  forbiddenModels.push(...previousModels.slice(-3));
  forbiddenModels.push(7, 8, 9, 11, 19);
  
  const availableModels = [...Array(20).keys()].filter(i => !forbiddenModels.includes(i));
  return availableModels[Math.floor(Math.random() * availableModels.length)];
}

function generateNextColor(previousPairs: Pairing[]) {
  const previousColors = previousPairs.map((pair: Pairing) => pair.colorIndex);
  const forbiddenColors: Set<number> = new Set(previousColors.slice(-3));
  const availableColors: number[] = [];

  for (let i = 0; i < COLORS_PER_PALETTE; i++) {
    if (!forbiddenColors.has(i) || i < 2 || (
      i !== availableColors[availableColors.length - 1] &&
      i !== availableColors[availableColors.length - 2]
    )) {
      availableColors.push(i);
    }
  }

  return availableColors[Math.floor(Math.random() * availableColors.length)];
}

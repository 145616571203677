import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useIdentityContext } from "~/identities/Chooser/hooks";
import { InitialIdentity } from "~/identities/constants";
import { SessionExpiringModal } from "../session-expiring-modal";
import analytics from "~/analytics";

interface SessionTimerProviderProps {
  children: React.ReactNode;
  /**
   * Dependency array -- timer will reset when any array elements change.
   */
}

const INITIAL_COUNT = 60;
const WARNING_COUNT = 30;

export const SessionTimerProvider = ({
  children,
}: SessionTimerProviderProps) => {
  const [count, setCount] = useState(INITIAL_COUNT);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const { screenState, setIdentity, setScreenState, resetHash, sessionStartTime, setSessionStartTime } =
    useIdentityContext();

  const shouldCountDown = useMemo(() => {
    return screenState !== "Intro" && screenState !== "Final" && count >= 0;
  }, [screenState, count]);

  // Function to reset the experience to show the intro screen and clear all data
  const resetExperience = useCallback(() => {
    setScreenState("Intro");
    setIdentity(InitialIdentity);
    setCount(INITIAL_COUNT);
    resetHash();
    const sessionEndTime = Date.now();
    const totalSessionTime = sessionEndTime - sessionStartTime;
    analytics.event("Experience_Abandoned", {
      time: Date.now()
    });
    analytics.event("Experience_Ended", {
      reason: "Experience_Abandoned",
      time: Date.now(),
      totalSessionTime: (totalSessionTime/1000) - 30,
      totalSessionMetric: (totalSessionTime/1000) - 30
    });
    gtag('event', 'session_end', {
      sessionControl: 'end'
    });
    gtag('config', 'GA_TRACKING_ID');
    setSessionStartTime(0);
  }, [resetHash, setIdentity, setScreenState, sessionStartTime, setSessionStartTime]);

  // User has indicated a desire to keep going
  const resetTimer = useCallback(() => {
    setCount(INITIAL_COUNT);
  }, []);

  // When screen state changes, reset the countdown
  useEffect(() => {
    resetTimer();
  }, [resetTimer, screenState]);

  // Show the warning modal when the countdown is below 30
  useEffect(() => {
    if (!shouldCountDown) {
      setShowWarningModal(false);
    } else {
      setShowWarningModal(count < (WARNING_COUNT+1) && count > 0);
    }
  }, [count, shouldCountDown]);

  // Count down
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((c) => c - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [shouldCountDown, count, screenState]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (shouldCountDown) {
      if (count <= 0 && shouldCountDown) {
        resetExperience();
      }
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [resetExperience, shouldCountDown, count]);

  return (
    <>
      {showWarningModal && (
        <SessionExpiringModal
          open={showWarningModal}
          onClose={resetTimer}
          count={count}
        />
      )}
      {children}
    </>
  );
};

import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import analytics from "~/analytics";
import { Text } from "~/ui";
import { useIdentityContext } from "../hooks";
import { getColor } from "~/ui/colors";
import { UserAttribute } from "../../types";
import { findCategoryForSubcategory } from "../../categories";
import { ChartBar } from "./ChartBar";

export function IdentityChart() {
  const {
    identity,
    setCategory,
    setSubCategory,
    setUserAttribute,
    setScreenState,
  } = useIdentityContext();
  const userAttributes = useMemo(() => {
    return identity.attributes;
  }, [identity.attributes]);

  // first take at collapsing bars on the identity chart dynamically
  // to handle lot of input.
  const collapseThreshold = useMemo(() => {
    if (userAttributes.length > 51) {
      return 0;
    }
    if (userAttributes.length > 46) {
      return 1;
    }
    if (userAttributes.length > 40) {
      return 2;
    }
    if (userAttributes.length > 34) {
      return 3;
    }
    if (userAttributes.length > 28) {
      return 4;
    }
    if (userAttributes.length > 24) {
      return 5;
    }
    if (userAttributes.length > 18) {
      return 6;
    }
    return 7;
  }, [userAttributes]);

  const selectedAttributeRef = useRef<UserAttribute | undefined>(undefined);

  const [touch, setTouch] = useState<Touch | undefined>(undefined);

  const CIRCLE_CENTER = {
    x: window.visualViewport!.width,
    y: window.visualViewport!.height / 2,
  };

  const CIRCLE_RADIUS = 500;

  const isWithinCircleRadius = (x: number, y: number): boolean => {
    const distance = Math.sqrt(
      (x - CIRCLE_CENTER.x) ** 2 + (y - CIRCLE_CENTER.y) ** 2
    );
    return distance < CIRCLE_RADIUS;
  };

  const handleTouchEvent = (e: TouchEvent) => {
    selectedAttributeRef.current = undefined;
    const touch = e.touches[0];
    const isWithin = isWithinCircleRadius(touch.clientX, touch.clientY);
    setTouch(isWithin ? touch : undefined);
  };

  const handleTouchEnd = (e: TouchEvent) => {
    setTouch(undefined);
    if (selectedAttributeRef.current) {
      const selectedAttribute = selectedAttributeRef.current;
      const category = findCategoryForSubcategory(
        selectedAttribute.subCategory
      );
      if (category) {
        setCategory(category);
        setSubCategory(
          category.subCategories.find(
            (s) => s.name === selectedAttribute.subCategory
          )
        );
        setUserAttribute({
          attribute: selectedAttribute.attribute,
          intensity: selectedAttribute.intensity,
          subCategory: selectedAttribute.subCategory,
        });
        setScreenState("IntensitySelection");
      }
    }
  };

  const CHART_LABEL_ID = "chart-label";
  const CHART_BARS_CONTAINER_ID = "chart-bars-container";

  // arbitrarily selected.
  const chartBarUpperBound = 95;

  const chartBarsContainerEl = document.getElementById(CHART_BARS_CONTAINER_ID);

  const [shouldCollapseTop, setShouldCollapseTop] = useState(false);

  const calAndSetShouldCollapseTop = () => {
    const chartBarElems = chartBarsContainerEl?.children;
    if (chartBarElems && chartBarElems.length > 2) {
      const shouldCollapseTop =
        chartBarElems[chartBarElems.length - 2].getBoundingClientRect().top -
          120 <
        chartBarUpperBound;
      setShouldCollapseTop(shouldCollapseTop);
    }
  };

  // @todo-nitz-critical fine tune this a bit.
  useEffect(() => {
    // this is a little bit hacky, but allows
    if (touch) {
      calAndSetShouldCollapseTop;
      setTimeout(calAndSetShouldCollapseTop, 200);
    } else {
      setShouldCollapseTop(false);
    }
  }, [touch]);

  return (
    <div>
      <ChartBackground
        onTouchStart={(e) => {
          handleTouchEvent(e as unknown as TouchEvent);
        }}
        onTouchMove={(e) => {
          handleTouchEvent(e as unknown as TouchEvent);
        }}
        onTouchEnd={(e) => {
          handleTouchEnd(e as unknown as TouchEvent);
        }}
      >
        <Text
          id={CHART_LABEL_ID}
          variant="body1"
          style={{
            position: "fixed",
            top: 32,
            right: 32,

            textAlign: "center",
            fontSize: 20,
            fontWeight: 700,
            letterSpacing: 4,
            textTransform: "uppercase",
          }}
        >
          My
          <br />
          Identity
        </Text>
        <div
          id={CHART_BARS_CONTAINER_ID}
          style={{ display: "flex", flexDirection: "column-reverse" }}
        >
          {userAttributes.map((attribute, index) => {
            const label = attribute.attribute.name;

            const backgroundColor = getColor(index);
            const color = getForegroundColor(backgroundColor);

            return (
              <ChartBar
                key={`${attribute.attribute.name}-${attribute.subCategory}`}
                id={`${attribute.attribute.name}-${attribute.subCategory}`}
                color={color}
                backgroundColor={backgroundColor}
                label={label}
                setSelectedItem={() => {
                  selectedAttributeRef.current = attribute;
                }}
                shouldCollapse={
                  index < userAttributes.length - collapseThreshold ||
                  (shouldCollapseTop && index === userAttributes.length - 1)
                }
                touch={touch}
              />
            );
          })}
        </div>
      </ChartBackground>
      {identity.attributes.length > 0 && <DoneButton />}
    </div>
  );
}

function DoneButton() {
  const { setScreenState, setSessionStartTime, sessionStartTime } = useIdentityContext();
  const handleClick = () => {
    const sessionEndTime = Date.now();
    const totalSessionTime = sessionEndTime - sessionStartTime;

    analytics.event("Identity_Submitted", {});
    analytics.event("Experience_Ended", {
      reason: "Identity_Submitted",
      time: Date.now(),
      totalSessionTime: (totalSessionTime/1000),
      totalSessionMetric: (totalSessionTime/1000)
    });    
    gtag('event', 'session_end', {
      sessionControl: 'end'
    });
    gtag('config', 'GA_TRACKING_ID');
    setScreenState("Final");
    setSessionStartTime(0);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "15vh",
        right: "35vh",
      }}
    >
      <button
        style={{
          width: "128px",
          height: "128px",
          borderRadius: 900,
          border: "7px #000000 solid",
          backgroundColor: "white",
          color: "black",
          textTransform: "uppercase",
          fontWeight: 700,
          letterSpacing: 3,
        }}
        onClick={handleClick}
      >
        Submit
        <br />
        All
      </button>
    </div>
  );
}

function ChartBackground({
  children,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
}: {
  children: ReactNode;
  onTouchStart: React.TouchEventHandler<HTMLDivElement>;
  onTouchMove: React.TouchEventHandler<HTMLDivElement>;
  onTouchEnd: React.TouchEventHandler<HTMLDivElement>;
}) {
  const size = `calc(100vh - 8px)`;
  return (
    <div
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      style={{
        boxSizing: "border-box",
        borderRadius: 500,
        width: "calc(100vh + 8px)",
        height: "calc(100vh + 16px)",
        position: "fixed",
        top: -8,
        right: "-50vh",
        border: "2px black solid",
      }}
    >
      <div
        style={{
          height: size,
          width: size,
          borderRadius: 500,
          overflow: "hidden",

          position: "fixed",
          right: "-50vh",
          top: 0,
          border: "2px black solid",
          display: "flex",
          alignItems: "flex-end",
        }}
      >
        <div style={{ width: "50%" }}>{children}</div>
        <div style={{ width: "50%" }}></div>
      </div>
    </div>
  );
}

function getForegroundColor(hexColor: string) {
  // Remove the '#' character if it's present
  hexColor = hexColor.replace("#", "");

  // Parse the hexadecimal color into its RGB components
  const r = parseInt(hexColor.slice(0, 2), 16);
  const g = parseInt(hexColor.slice(2, 4), 16);
  const b = parseInt(hexColor.slice(4, 6), 16);

  // Calculate the relative luminance (perceptual brightness) of the background color
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  // Determine the most appropriate foreground color based on luminance
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
}

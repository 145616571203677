import { useEffect, useState } from "react";
import { Box, Text } from "~/ui";

import { useIdentityContext } from "./hooks";
import { ScreenContainer } from "~/ui/screen";
import { BackButton } from "./BackButton";
import { IntensitySlider } from "./IntensitySlider";
import { IdentityChart } from "./IdentityChart/IdentityChart";
import { UserAttribute } from "../types";
import analytics from "~/analytics";
import { reportIdentityToAPI } from "~/api";
import { useHash } from "~/hash";

const INITIAL_INTENSITY = 50;

export function IntensitySelectionScreen() {
  const hash = useHash();
  const {
    userAttribute,
    setUserAttribute,
    identity,
    setIdentity,
    subCategory,
    setScreenState,
    colorPalette
  } = useIdentityContext();
  const [intensity, setIntensity] = useState(
    userAttribute?.intensity || INITIAL_INTENSITY
  );
  const handleSliderChange = (v: number) => {
    setIntensity(v);
    if (userAttribute?.attribute) {
      setUserAttribute({
        attribute: userAttribute?.attribute,
        intensity,
        subCategory: subCategory?.name ?? "",
      });
    }
  };

  // set the appropriate intensity to state if user switches to different bar on the chart
  useEffect(() => {
    // this seems to be unneeded at this point?
    // if (userAttribute?.intensity && userAttribute.intensity !== intensity) {
    //   setIntensity(userAttribute.intensity);
    // }
  }, [userAttribute?.attribute.name, intensity, userAttribute?.intensity]);

  const handleDone = () => {
    if (!userAttribute || !hash) return;
    const newAttributes: UserAttribute[] = [...identity.attributes];
    const matchingExistingAttribute = newAttributes.findIndex(
      (a) =>
        a.subCategory === subCategory?.name &&
        a.attribute.name === userAttribute.attribute.name
    );
    // if attribute is already selected (intensity is being adjusted) replace the existing selection.
    if (matchingExistingAttribute > -1) {
      newAttributes[matchingExistingAttribute] = userAttribute;
    } else {
      newAttributes.push(userAttribute);
    }
    const newIdentity = { ...identity, attributes: newAttributes };
    setIdentity(newIdentity);

    // Report identity to the API
    reportIdentityToAPI(newIdentity, hash, colorPalette);

    // Report chosen attribute
    analytics.event("Attribute_Selected", {
      attribute: userAttribute?.attribute.name,
      intensity,
      time: Date.now()
    });

    setUserAttribute(undefined);
    setScreenState("AttributeSelection");
  };

  return (
    <ScreenContainer>
      <Box>
        <BackButton />
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box
          style={{
            width: "55%",
          }}
        >
          <Text
            variant="body1"
            style={{
              fontSize: "34px",
              textAlign: "left",
            }}
          >
            If you’d like, slide the scale to indicate to what degree you feel
            that this attribute has informed{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              your lived experience.
            </span>
          </Text>
        </Box>
        {userAttribute && (
          <IntensitySlider
            userAttribute={userAttribute}
            onDone={handleDone}
            onChange={handleSliderChange}
            intensity={intensity}
          />
        )}
        <IdentityChart />
      </Box>
    </ScreenContainer>
  );
}

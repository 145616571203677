import _ from "lodash";
import { Box, Text, Button } from "~/ui";
import { ScreenContainer } from "~/ui/screen";
import { Header } from "./Header";
import { PALETTES_V1, PALETTE, PALETTES } from "~/colors";
import { useIdentityContext } from "./hooks";

export function ColorPaletteSelectionScreen() {
  const context = useIdentityContext();
  const handleClick = (value: PALETTE) => {
    context.setColorPalette(value);
    context.setScreenState("CategorySelection");
  };

  const handleRandomizeClick = () => {
    const palette = _.sample(PALETTES);
    context.setColorPalette(palette || null);
    context.setScreenState("CategorySelection");
  }

  const integerToHex = (integer: number): string => {
    let hex = integer.toString(16);
    while (hex.length < 6) {
      hex = `0${hex}`;
    }
    return `#${hex}`;
  }

  return (
    <ScreenContainer>
      <Header />
      <Text
        variant="body1"
        style={{
          fontSize: "34px",
        }}
      >
        Select a color palette for your personalized kaleidoscope.
      </Text>
      <Box 
        display="flex"
        justifyContent="space-between"
        pt={4}
        style={{ width: '100%', margin: "0px auto", display: 'flex', flexWrap: 'wrap', marginTop: '-2%' }}
      >
        {PALETTES_V1.map((palette: PALETTE, i: number) => (
          <div style={{ height: '25%', width: '49%', border: '2px black solid', borderRadius: '8px', marginBottom: '10px', alignItems: 'center' }}>
            <Text variant="body1" style={{ fontSize: '20px', fontWeight: 700, marginTop: '10px', marginBottom: 0 }}>
              {`PALETTE ${i + 1}`}
            </Text>
            <div style={{ padding: '0px 10px 10px 10px', width: 'auto', marginBottom: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'space-around' }} onClick={() => handleClick(palette)}>
              {palette.map((color: number) => {
                const hex = integerToHex(color);
                return (
                  <div 
                    style={{ display: 'inline-flex', backgroundColor: hex, height: '60px', width: '16%' }}
                  />
                )
              })}
            </div>
          </div>
        ))}
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Button style={{ padding: '0% 2%' }} onClick={handleRandomizeClick}>
            <Text variant="h2">CHOOSE PALETTE FOR ME</Text>
          </Button>
        </div>
      </Box>
    </ScreenContainer>
  )
}